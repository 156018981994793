<template>
  <img
    id="logo"
    alt="Logo"
    :src="require('@/assets/images/logo/' + imageName)"
  >
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageName() {
      return this.dark ? 'logo-dark.png' : 'logo.png'
    },
  },
}
</script>

<style scoped>
 #logo {
   max-width: 200px;
   display: block;
   margin: 2rem auto;
 }
</style>
